<div class="container py-4">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'resetPassword.title' | translate }}</h4>
        </div>

        <div class="card-body">
          <h6 class="mb-4">
            {{ 'resetPassword.description' | translate }}
          </h6>

          <div class="alert alert-success" *ngIf="success">
            <span>{{ 'resetPassword.messages.success' | translate }} <a class="alert-link" (click)="login()">{{ 'resetPassword.messages.login' | translate }}</a></span>
          </div>

          <form *ngIf="!success" name="form" role="form" (ngSubmit)="finishReset()" [formGroup]="passwordForm">
            <div class="form-group">
              <label
                class="form-control-label" for="password"
              >{{ 'resetPassword.newpassword.label' | translate }}</label>
              <input
                type="password" class="form-control text-center" id="password" name="password"
                placeholder="{{ 'login.password' | translate }}"
                formControlName="newPassword"
              >
              <div
                *ngIf="passwordForm.get('newPassword').invalid && (passwordForm.get('newPassword').dirty || passwordForm.get('newPassword').touched)"
              >
                <small
                  class="form-text text-danger"
                  *ngIf="passwordForm.get('newPassword').errors.required"
                >{{ 'resetPassword.password.required' | translate }}</small>
                <small
                  class="form-text text-danger"
                  *ngIf="passwordForm.get('newPassword').errors.minlength"
                >{{ 'resetPassword.password.minlength' | translate }}</small>
                <small
                  class="form-text text-danger"
                  *ngIf="passwordForm.get('newPassword').errors.maxlength"
                >{{ 'resetPassword.password.maxlength' | translate }}</small>
              </div>
              <jhi-password-strength-bar
                [passwordToCheck]="passwordForm.get('newPassword').value"
              ></jhi-password-strength-bar>
            </div>

            <div class="form-group">
              <label
                class="form-control-label" for="confirmPassword"
              >{{ 'resetPassword.confirmpassword.label' | translate }}</label>
              <input
                type="password" class="form-control text-center" id="confirmPassword" name="confirmPassword"
                placeholder="{{ 'login.password' | translate }}"
                formControlName="confirmPassword"
              >
              <div
                *ngIf="passwordForm.get('confirmPassword').invalid && (passwordForm.get('confirmPassword').dirty || passwordForm.get('confirmPassword').touched)"
              >
                <small
                  class="form-text text-danger"
                  *ngIf="passwordForm.get('confirmPassword').errors.required"
                >{{ 'resetPassword.password.required' | translate }}</small>
                <small
                  class="form-text text-danger"
                  *ngIf="passwordForm.get('confirmPassword').errors.minlength"
                >{{ 'resetPassword.password.minlength' | translate }}</small>
                <small
                  class="form-text text-danger"
                  *ngIf="passwordForm.get('confirmPassword').errors.maxlength"
                >{{ 'resetPassword.password.maxlength' | translate }}</small>
              </div>
            </div>

            <div class="alert alert-danger" *ngIf="error">
              {{ 'resetPassword.messages.error' | translate }}
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch">
              {{ 'resetPassword.messages.doesnotmatch' | translate }}
            </div>

            <button
              type="submit" [disabled]="isSubmitting || passwordForm.invalid" class="btn btn-primary"
            >{{ 'resetPassword.button.submit' | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

